<template>
  <v-expansion-panel @change="onPanelChange">
    <v-expansion-panel-header>
      <v-list-item :class="{ primary: isPrimary }">
        <v-list-item-avatar color="grey lighten-3">
          <v-img
            :src="item.preview_image"
            :alt="item.firm.name"
            v-if="item.preview_image"
          />
          <icon-camera outlined size="1.8rem" v-else />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="item.firm.name" />
          <v-list-item-subtitle v-text="item.firm.email" />
        </v-list-item-content>
      </v-list-item>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>

      <v-simple-table v-else>
        <thead>
          <tr>
            <th class="text-left" style="width: 100px">Seleccionar</th>
            <th class="text-left">Nombre</th>
            <th class="text-left">Codigo</th>
            <th class="text-left">Dirección</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(branch, index) in branches" :key="index">
            <td class="text-left">
              <v-checkbox
                v-model="branch.selected"
                :value="branch.id"
              ></v-checkbox>
            </td>
            <td>{{ branch.firm.dgi_denominacion }}</td>
            <td>{{ branch.firm.code }}</td>
            <td>{{ branch.firm.dgi_dir_fiscal }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-btn v-if="!loading" color="primary" @click="applySelection"
        >Aplicar selección</v-btn
      >
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { BranchCollection } from "@planetadeleste/vue-mc-gw";

@Component
export default class CompanyBranchesList extends Vue {
  @Prop(Object) readonly item!: any;
  @Prop(Array) readonly arBranchList!: number[];

  branches: any[] = [];
  loading = false;
  panelOpen = false;

  get isPrimary() {
    return this.arBranchList.includes(this.item.id);
  }

  applySelection() {
    const selectedBranches = this.branches.filter((branch) => branch.selected);
    this.$emit("selection-applied", selectedBranches);
  }

  onPanelChange() {
    this.panelOpen = !this.panelOpen;
    if (this.panelOpen) {
      this.load();
    }
  }

  async load() {
    this.loading = true;
    const obCollection = new BranchCollection();
    obCollection.page(1);

    obCollection.byCompany(this.item.id);

    await obCollection.fetch();
    this.branches = obCollection.models;
    this.loading = false;
  }
}
</script>

<style scoped>
/* Agrega estilos específicos para este componente aquí */
</style>
